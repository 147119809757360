.unsubscribe-page {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding: 50px 20px;
    box-sizing: border-box;

    .logo {
        text-align: center;

        img {
            max-height: 100px;
        }
    }

    h1 {
        font-size: 24px;
        text-align: center;
    }

    button {
        padding: 15px 50px;
        border: none;
        border-radius: 25px;
        background-color: #333;
        color: #fff;
        margin: 0 auto;
        display: block;
    }

    .success-message {
        text-align: center;
    }
}