.nps-page .logo {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.nps-page .logo img {
    max-height: 60px;
}

.nps-form {
    max-width: 400px;
    margin: 0 auto;
}

.nps-form p {
    font-size: 16px;
    text-align: center;
    margin-top: 30px;
}

.nps-score-container {
    margin-top: 30px;
}

.nps-score-container .legend {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    color: #999;
    justify-content: space-between;
    margin-top: 5px;
}

.nps-score-container .nps-score-rating {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    margin: 0 auto;
    justify-content: space-between;
}

.nps-score-rating .score {
    border: 1px solid #FADAD9;
    background: #FADAD9;
    color: #740000;
    font-size: 15px;
    width: 33px;
    height: 33px;
    line-height: 31px;
    border-radius: 5px;
    cursor: pointer;
}

.nps-score-rating .score.active {
    background-color: #F0908D;
    border-color: #740000;
}

.nps-score-rating .score-7, .nps-score-rating .score-8 {
    border-color: #FCEAD4;
    background-color: #FCEAD4;
    color: #986502
}

.nps-score-rating .score-7.active, .nps-score-rating .score-8.active {
    background-color: #F5C07E;
    border-color: #986502;
}

.nps-score-rating .score-9, .nps-score-rating .score-10 {
    background-color: #D7F0D6;
    border-color: #D7F0D6;
    color: #005921
}

.nps-score-rating .score-9.active, .nps-score-rating .score-10.active {
    background-color: #87D185;
    border-color: #005921;
}

.nps-page textarea {
    border: 1px solid #e5e5e5;
    resize: none;
    width: 100%;
    border-radius: 10px;
    height: 150px;
    padding: 10px;
    box-sizing: border-box;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
}

.nps-page .submit-button {
    background-color: #333;
    color: #fff;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: none;
    margin-top: 20px;
    height: 40px;
    font-size: 16px;
}

.nps-page .submit-button:disabled {
    opacity: 0.2;
}

@media screen and (max-width: 400px) {
    .nps-score-container .nps-score-rating {
        max-width: 360px;
    }

    .nps-score-rating .score { 
        width: 30px;
        line-height: 28px;
        height: 30px;
    }
}

@media screen and (max-width: 375px) {
    .nps-score-rating .score { 
        width: 25px!important;
        line-height: 23px!important;
        height: 25px!important;
        padding: 1px 4px!important;
        font-size: 14px;
    }
}