body {
    background-color: #f8f8f8;
    margin: 0;
}

.loading-container {
    width: 100%;
    text-align: center;
}

.linktree-page {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: linear-gradient(to bottom right, var(--bg-color1), var(--bg-color2));
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    height: 100vh;

    .inner {
        height: 100vh;
        overflow: auto;
        width: 100%;
    }

    .linktree {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        box-sizing: border-box;
        margin-bottom: 203px;
    }

    .logo {

        margin: 10px 0;
        text-align: center;
        line-height: 60px;

        img {
            max-height: 60px;
            max-width: 80%;
            vertical-align: middle;
        }
    }

    a.link {
        background-color: rgba(255, 255, 255, 0.9);
        width: 90%;
        max-width: 400px;
        padding: 14px;

        border-radius: 40px;
        color: #000;
        text-decoration: none;

        div.link {
            display: flex;
            flex-direction: row;
            padding: 0 14px;
            align-items: center;

            .icon {
                font-size: 40px;
            }

            .content {
                display: flex;
                flex-direction: column;
                margin-left: 16px;
                flex: 1;

                .line {
                    font-size: 12px;
                    text-align: center;
                    line-height: 20px;
                    text-transform: uppercase;

                    &.title {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
            }


        }


    }

    .rate {
        width: 90%;
        max-width: 600px;
        position: absolute;
        bottom: 20px;
        border-radius: 10px;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        color: #000;
        text-decoration: none;
        border: 0;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.2);

        .stars {
            width: 90%;
            max-width: 300px;
        }

        h3 {
            font-size: 20px;
            margin: 0;
            font-weight: 600;
            text-transform: uppercase;
        }

        .promotionText {
            text-align: center;
            font-size: 13px;
            font-weight: bold;
        }
    }
}