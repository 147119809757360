.review-topic-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
}

.review-topic-score {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
  & div {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.selected {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &.selected:nth-of-type(1) {
      background-color: #740000;
    }
    &.selected:nth-of-type(2) {
      background-color: #986502;
    }
    &.selected:nth-of-type(3) {
      background-color: #005921;
    }
  }
  & img {
    height: 24px;
    width: 24px;
  }
}
