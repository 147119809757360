.nps-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;

    &.show {
        display: block;
    }
}

body.nps {
    background: #fff;
}

.nps-page {
    &.modal {
        position: absolute;
        visibility: hidden;
        top: 90px;
    bottom: 0;
    right: 20px;
    left: 20px;

    background: #fff;
    border-radius: 10px;
    box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.05);
    box-sizing: border-box;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    transform: translateY(100%);
    transition: 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 9999;
    overflow-x: hidden;


        &.show {
            visibility: visible;
            transform: translateY(0);
        }

        .back-button {
            position: absolute;
            top: 10px;
            left: 10px;
            border: 0;
            width: 26px;
            height: 26px;
            background-color: #fff;
            cursor: pointer;
            border: none!important;
    
            img {
                width: 26px;
                height: 26px;
            }
        }
    
        .close-button {
            position: absolute;
            top: 10px;
            right: 30px;
            border: 0;
            width: 26px;
            height: 26px;
            border: none!important;
            background-color: #fff;
            cursor: pointer;
    
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
}

.success-message {
    padding: 0 20px;

    h2 {
        font-size: 36px;
        font-weight: lighter;
        text-transform: uppercase;
        text-align: center;
        color: #0B775A;
    }

    p {
        color: #313131;
        text-align: center;
    }
}

.error-message {
    padding: 0 20px;

    h2 {
        font-size: 24px;
        font-weight: lighter;
        text-transform: uppercase;
        text-align: center;
        color: #0B775A;
    }

    p {
        color: #313131;
        text-align: center;
    }
}