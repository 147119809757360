.stars-rating .star {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}

.stars-rating .star img {
    height: 35px;
    filter: grayscale(1);
}

.stars-rating .star.active img {
    filter: none;

    
}

.stars-rating .star.active img {
    fill: #C28B17;
}

@media screen and (max-width:400px) {
    .stars-rating .star img { 
        max-width: 30px;
    }
}