body {
    background-color: #f8f8f8;
    margin: 0;
}

.loading-container {
    width: 100%;
    text-align: center;
}

.rate-external-page {
    width: 80%;
    max-width: 700px;
    margin: 20px auto;
    box-sizing: border-box;

    .logo {
        text-align: center;
        margin-bottom: 30px;

        img {
            max-height: 60px;
        }
    }

    .info {
        text-align: center;
    }

    input.document {
        background: #fff;
        border: 1px solid #acacac;
        width: 100%;
        display: block;
        padding: 15px 10px;
        border-radius: 8px;
        box-sizing: border-box;
        margin: 30px 0;
        font-size: 16px;
        text-align: center;
    }

    .error {
        color: brown;
        margin-top: 20px;
        text-align: center;
    }

    button {
        background-color: #666;
        color: #fff;
        border: none;
        padding: 15px 10px;
        width: 100%;
        border-radius: 8px;
        font-size: 16px;

        .lds-ring {
            width: 24px;
            height: 24px;
            position: absolute;
            margin-top: -8px;
            margin-left: 10px;

            div {
                width: 19px;
                height: 19px;
                border-width: 3px;
                border-color: #fff transparent transparent transparent;
            }
        }

        &:disabled {
            opacity: 0.4;
            cursor: unset;
        }
    }
}


.orders {
    width: 100%;
    box-sizing: border-box;
    padding: 25px 0;

    a {
        color: #333;
        text-decoration: none;
    }

    .order {
        width: 100%;
        background-color: #fff;
        margin: 0 0 30px 0;
        padding: 12px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        overflow: hidden;
        position: relative;

        .order-id {
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: bold;
        }

        .order-date {
            font-size: 16px;
        }

        .products {
            margin-top: 10px;
            font-size: 14px;
        }
    }
}