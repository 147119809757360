.rate-product-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;

    &.show {
        display: block;
    }
}


.rate-product {
    &.modal {
        position: absolute;
        visibility: hidden;
    }
    font-family: Lato;
    top: 90px;
    bottom: 0;
    right: 20px;
    left: 20px;

    background: #fff;
    border-radius: 10px;
    box-shadow: 0px -2px 4px 0px rgba(0,0,0,0.05);
    box-sizing: border-box;
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    transform: translateY(100%);
    transition: 0.3s ease-in-out;
    overflow-y: auto;
    z-index: 9999;
    overflow-x: hidden;

    &.show {
        visibility: visible;
        transform: translateY(0);
    }

    .back-button {
        position: absolute;
        top: 10px;
        left: 10px;
        border: 0;
        width: 26px;
        height: 26px;
        background-color: #fff;
        cursor: pointer;
        border: none!important;

        img {
            width: 26px;
            height: 26px;
        }
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 30px;
        border: 0;
        width: 26px;
        height: 26px;
        border: none!important;
        background-color: #fff;
        cursor: pointer;

        img {
            width: 26px;
            height: 26px;
        }
    }

    .step1 {
        transition: all 0.3s ease-in-out;

        &.done {
            transform: translateX(-110%);
        }
    }

    .step2 {
        padding: 10px;
        transition: all 0.3s ease-in-out;
        // transform: translateX(110%);

        &.active {
            transform: none;
        }


        .thanks {

            background: url(/./done_green.png) no-repeat;
            background-size: contain;
            box-sizing: border-box;
            padding-left: 70px;
            height: 60px;

            h4 {
                font-weight: bold;
                font-size: 18px;
                margin: 0;/* 0 7px 0;*/
                line-height: 32px;
                // padding-top: 5px;;
            }

            p {
                margin: 0;
            }
        }

        .attributes {
            margin-top: 20px;

            select {
                margin-top: 10px;
                padding: 5px 10px;
                border-radius: 6px;
            }
        }
        
    }

    .product-details {
        text-align: center;

        img {
            width: 120px;
            height: 120px;
            object-fit: contain;
        }

        .product-name {
            margin: 10px 0;
        }
    }

    .label {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
    }

    .attribute-description {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .description {
        font-size: 14px;
        color: #666;
        display: block;
        margin-top: 10px;
    }

    .choice-label {
        display: block;
    }

    .rating-container {

        .stars-rating {
            margin-top: 10px;
            display: inline-block;
            vertical-align: middle;
        }

        .rating {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            margin-top: 10px;
            line-height: 38px;
            font-size: 18px;
        }
    }

    .options {
        display: flex;
        flex-direction: row;
    }

    label.option {
        margin-top: 15px;
        display: inline-block;
        margin-right: 10px;

        @media screen and(max-width: 767px) {
            display: block;
        }
    }

    .text-container {
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        margin-top: 10px;
        position: relative;
    }

    textarea {
        border: none;
        border-radius: 5px;
        width: 100%;
        display: block;
        height: 80px;
        box-sizing: border-box;
        padding: 10px;
        font-family: Lato;
        font-size: 14px;
        resize: none;
    }

    .loading-icon {
        position: absolute;
        right: 10px;
    }

    .completeness {
        background-color: #fdfdfd;
        border-top: 1px solid #d9d9d9;
        border-radius: 0 0 5px 5px;

        .completeness-label {
            font-size: 11px;
            padding: 10px 10px 0 10px;
        }

        .completeness-topics {
            display: flex;
            width: 100%;

            .completeness-topic {
                flex: 1;
                padding: 10px 10px 10px 40px;
                font-size: 14px;
                text-transform: lowercase;
                background:url('/k_indicator.png') no-repeat;
                background-position: 20px 14px;
                background-size: 12px;
                transition: all 1s ease-in-out;

                &.complete {
                    color: #009C39;
                    font-weight: bold;
                    animation: complete-topic 1s ease-in-out;

                    background:url('/check_icon.png') no-repeat;
                background-position: 20px 14px;
                background-size: 12px;
                }
            }
        }

        .completeness-progress {
            background-color: #D8D8D8;
            border-radius: 0px 0px 4px 4px;
            width: 100%;
            height: 10px;
            

            .indicator {
                background-color: #009C39;
                height: 10px;
                transition: width 2s ease-in-out;
                border-radius: 0 0 0 4px;

                &.complete {
                    border-radius: 0 0 4px 4px;
                }
            }
        }
    }

   

    input[type=text], input[type=tel] {
        display: block;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        width: 50%;
        margin-top: 10px;
        padding: 10px;

        @media screen and (max-width: 767px) {
                width: 100%;
                box-sizing: border-box;
        }
    }

    ul.pictures {
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
            position: relative;
            vertical-align: top;
            margin-right: 20px;
            border: 2px solid #d8d8d8;
            border-radius: 6px;
            width: 60px;
            height: 60px;


            &.add {
                border:2px dashed #d8d8d8;
                

                button {
                    width: 60px;
                    height: 60px;
                    border: none;
                    appearance: none;
                    padding: 0;
                    margin: 0;
                    border-radius: 5px;
                    background: url(./add.png) no-repeat center center #f7f7f7;
                    background-size: 30%;
                }
            }

            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 4px;
            }

            span {
                position: absolute;
                top: -10px;
                right: -10px;
                z-index: 999;
                border-radius: 10px;
                background: #999;
                width: 20px;
                height: 20px;
                text-align: center;
                color: #fff;
                font-size: 12px;
                line-height: 20px;
                font-weight: bold;
            }
        }
    }        

    .file-upload {
        display: none;
    }

    .customer-consent-pictures {
        margin-top: 20px;
    }

    button.button {
        background-color: #fff;
        color: #666;
        border: 1px solid #666;
        padding: 10px 22px;
        border-radius: 5px;
        margin-top: 15px;
        width: 100%;
        font-size: 16px;
        

        &.picture {
            background-image: url(/./camera.png);
            background-repeat: no-repeat;
            background-position: 20px 5px;
            background-size: 30px;
            background-position: calc(50% - 60px);
            padding: 10px 22px 10px 60px;
        }
        
        @media screen and (min-width: 767px) {
            width: auto;
        }
    }

    button[type=submit] {
        margin-top: 15px;
        background: #666;
        color: #fff;
        border: none;
        padding: 10px 30px;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        font-size: 16px;

        &:disabled {
            opacity: 0.4;
            cursor: unset;
        }

        @media screen and (min-width: 767px) {
            width: auto;
            float: right;
        }
    }

    .error {
        color: #ce4747;
        margin-top: 20px;
        text-align: center;
    }

}

@keyframes complete-topic {
    0% {
        text-shadow: 0px 0px 16px rgba(0,156,57,0);
    }

    50% {
        text-shadow: 0px 0px 16px #009c39;
    }

    95% {
        text-shadow: 0px 0px 16px #009c39;
    }

    100% {
        text-shadow: 0px 0px 16px rgba(0,156,57,0);
    }
    
}