body {
  background-color: #f8f8f8;
  margin: 0;
}

.loading-container {
  width: 100%;
  text-align: center;
}

.rate-order-page {
  width: 100%;
  max-width: 700px;
  margin: 20px auto;
}

.rate-order-page .logo {
  text-align: center;
  margin-bottom: 30px;
}

.rate-order-page .logo img {
  max-height: 60px;
}

h3 {
  font-size: 22px;
  text-align: center;
  margin: 40px 10px 20px 10px;
}

.order-items {
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.order-item {
  width: 100%;
  background-color: #fff;
  margin: 0 0 30px 0;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.order-item.done {
  cursor: unset;
}

.order-item .badge::after {
  content: "";
  position: absolute;
  top: 8px;
  right: 5px;
  background: url("/./src/done_white.png") no-repeat;
  width: 30px;
  height: 30px;
  background-size: contain;
}

.order-item .badge::before {
  content: "";
  position: absolute;
  top: -8px;
  right: -35px;
  transform: rotate(45deg);
  background: #000;
  height: 50px;
  width: 110px;
  z-index: 0;
  opacity: 0.8;
}

.order-item .picture {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 12px;
}

.coupon-div {
  max-width: 700px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
  text-align: center;
  font-style: normal;
  color: #ffffff;
}
.coupon-div .coupon-text {
  width: 100%;
  height: 128px;
  box-sizing: border-box;
  padding: 15px;
  background-color: #666666;
  border-radius: 8px;
  line-height: 20px;
}
.coupon-text .line1 {
  font-weight: 700;
  font-size: 13px;
  margin: 0;
}
.coupon-text .line2 {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  line-height: 25px;
}
.coupon-text .line3 {
  font-weight: 100;
  font-size: 11px;
  margin: 0;
  opacity: 0.7;
}
.coupon-text .line4 {
  font-weight: 700;
  font-size: 13px;
  line-height: 50px;
  margin-top: 0px;
}
.coupon-text .icone {
  vertical-align: sub;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.coupon-text a:link {
  text-decoration: none;
  color: #ffffff;
}

.coupon-text a:visited {
  text-decoration: none;
  color: #ffffff;
}

.coupon-text a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.coupon-text a:active {
  text-decoration: underline;
  color: #ffffff;
}
.order-item .storeLogo {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 12px;
}


.rate-order-page .nps-score-container {
  max-width: 400px;
  margin: 0 auto;
}

.rate-order-page .nps-score-container .nps-score-rating .score {
  width: 30px;
  height: 30px;
  line-height: 26px;
}

.rate-order-page .nps-score-container .nps-score-rating {
  margin-top: 20px;
}

.rate-order-page .order-items.store-review-first .order-item {
  order: 2;
}

.rate-order-page .order-items.store-review-first .order-item.store-review {
  order:1;
}

.rate-order-page .order-items.store-review-first .order-item.nps {
  order:0;
}